<template>
  <div class="main-content">
    <b-overlay
      :show="showOverlay"
      opacity="0.60"
      rounded="sm"
      variant="white"
      no-wrap
    ></b-overlay>
    <h2 class="mb-4">{{ mode === 'add' ? 'Add Product' : 'Edit Product'}}</h2>
    <!-- <div class="wrapper"> -->
    <b-row>
      <b-col md="12 mb-30">
        <b-card>
          <!-- <b-form @submit.prevent="submit" novalidate> -->
          <b-row>
            <b-form-group
              class="col-md-6 mb-3"
              label="Name"
              label-for="input-1"
            >
              <b-form-input
                v-model="form.name"
                type="text"
                required
                placeholder="Name"
                size="lg"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="col-md-6 mb-3"
              label="Code"
              label-for="input-1"
            >
              <b-form-input
                v-model="form.code"
                type="text"
                required
                placeholder="Code"
                size="lg"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="col-md-6 mb-3"
              label="Product Image"
              label-for="input-1"
            >
              <b-form-file
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @change="uploadProof"
              ></b-form-file>

              <div v-if="mode === 'edit'">
                <img class="img-thumbnail p-2 mt-3" :src="getFullImageUrl(form.productUrl)" alt="Image preview"/>
              </div>
            </b-form-group>

            <b-form-group label="Brand" label-for="input-1" class="col-md-6">
              <b-form-select
                v-model="form.brandId"
                id="inline-form-custom-select-pref1"
                size="lg"
              >
                <b-form-select-option
                  :value="brand.id"
                  v-for="(brand, index) in brandOptions"
                  :key="index"
                  >{{ brand.name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <b-form-group label="Category" label-for="input-1" class="col-md-6">
              <!-- <b-form-input
                  list="input-list"
                  id="input-with-list"
                ></b-form-input>
                <b-form-datalist
                  id="input-list"
                  :options="category"
                ></b-form-datalist> -->
              <b-form-select
                v-model="form.categoryId"
                id="inline-form-custom-select-pref1"
                size="lg"
              >
                <b-form-select-option
                  :value="cat.id"
                  v-for="(cat, index) in categoryOptions"
                  :key="index"
                  >{{ cat.name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <b-form-group
              label="Base unit"
              label-for="input-1"
              class="col-md-6"
            >
              <b-form-select
                v-model="form.unitId"
                id="inline-form-custom-select-pref1"
                size="lg"
              >
                <b-form-select-option
                  :value="cat.id"
                  v-for="(cat, index) in baseUnit"
                  :key="index"
                  >{{ cat.name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <b-form-group
              label="Warehouse"
              label-for="input-1"
              class="col-md-6"
            >
              <b-form-select
                v-model="form.warehouseId"
                id="inline-form-custom-select-pref1"
                size="lg"
              >
                <b-form-select-option
                  :value="cat.id"
                  v-for="(cat, index) in warehouses"
                  :key="index"
                  >{{ cat.name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <b-form-group label="Hardness" label-for="input-1" class="col-md-6">
              <b-form-select
                v-model="form.variant1Id"
                id="inline-form-custom-select-pref1"
                size="lg"
              >
                <b-form-select-option
                  :value="cat.id"
                  v-for="(cat, index) in hardness"
                  :key="index"
                  >{{ cat.name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <b-form-group
              label="Sharpness"
              label-for="input-1"
              class="col-md-6"
            >
              <b-form-select
                v-model="form.variant2Id"
                id="inline-form-custom-select-pref1"
                size="lg"
              >
                <b-form-select-option
                  :value="cat.id"
                  v-for="(cat, index) in sharpness"
                  :key="index"
                  >{{ cat.name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <b-form-group label="Color" label-for="input-1" class="col-md-6">
              <b-form-select
                v-model="form.variant3Id"
                id="inline-form-custom-select-pref1"
                size="lg"
              >
                <b-form-select-option
                  :value="cat.id"
                  v-for="(cat, index) in color"
                  :key="index"
                  >{{ cat.name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <b-form-group label="Shape" label-for="input-1" class="col-md-6">
              <b-form-select
                v-model="form.variant4Id"
                id="inline-form-custom-select-pref1"
                size="lg"
              >
                <b-form-select-option
                  :value="cat.id"
                  v-for="(cat, index) in shape"
                  :key="index"
                  >{{ cat.name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <b-form-group
              label="Number of faces"
              label-for="input-1"
              class="col-md-6"
            >
              <b-form-select
                v-model="form.variant5Id"
                id="inline-form-custom-select-pref1"
                size="lg"
              >
                <b-form-select-option
                  :value="cat.id"
                  v-for="(cat, index) in nooffaces"
                  :key="index"
                  >{{ cat.name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <b-form-group label="Clarity" label-for="input-1" class="col-md-6">
              <b-form-select
                v-model="form.variant6Id"
                id="inline-form-custom-select-pref1"
                size="lg"
              >
                <b-form-select-option
                  :value="cat.id"
                  v-for="(cat, index) in clarity"
                  :key="index"
                  >{{ cat.name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <b-form-group
              label="Treatment"
              label-for="input-1"
              class="col-md-6"
            >
              <b-form-select
                v-model="form.variant7Id"
                id="inline-form-custom-select-pref1"
                size="lg"
              >
                <b-form-select-option
                  :value="cat.id"
                  v-for="(cat, index) in treatment"
                  :key="index"
                  >{{ cat.name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <b-form-group label="Effect" label-for="input-1" class="col-md-6">
              <b-form-select
                v-model="form.variant8Id"
                id="inline-form-custom-select-pref1"
                size="lg"
              >
                <b-form-select-option
                  :value="cat.id"
                  v-for="(cat, index) in effect"
                  :key="index"
                  >{{ cat.name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <b-form-group label="Tax" label-for="input-1" class="col-md-6">
              <!-- <b-form-select
                  v-model="form.productTaxes"
                  id="inline-form-custom-select-pref1"
                  size="lg"
                >
                <b-form-select-option
                    :value="cat.id"
                    v-for="(cat, index) in taxOptions"
                    :key="index"
                    >{{ cat.name }}</b-form-select-option
                  >
                
                </b-form-select> -->
              <vue-multi-select
                class="multi-select"
                ref="multiSelect"
                v-model="form.productTaxes"
                historyButton
                :options="taxConfigoptions"
                @open="open"
                @close="close"
                :btnLabel="btnLabel"
                :selectOptions="taxOptions"
                btnClass="form-control form-control-lg"
              >
                <!-- <template v-slot:option="{option}">
                    satzz
                    <input type="checkbox" :checked="option.selected"/>
                    <span>{{option.name}}</span>
                  </template> -->
              </vue-multi-select>
            </b-form-group>

            <b-form-group
              class="col-md-6 mb-3"
              label="SKU Code"
              label-for="input-1"
            >
              <b-form-input
                v-model="form.skuCode"
                type="text"
                required
                placeholder="SKU Code"
                size="lg"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="col-md-6 mb-3"
              label="SKU Name"
              label-for="input-1"
            >
              <b-form-input
                v-model="form.skuName"
                type="text"
                required
                placeholder="SKU name"
                size="lg"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="col-md-6 mb-3"
              label="Sales Price"
              label-for="input-1"
            >
              <b-form-input
                v-model="form.salesPrice"
                type="text"
                required
                placeholder="Sales Price"
                size="lg"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="col-md-6 mb-3"
              label="Purchase Price"
              label-for="input-1"
            >
              <b-form-input
                v-model="form.purchasePrice"
                type="text"
                required
                placeholder="Purchase Price"
                size="lg"
              ></b-form-input>
            </b-form-group>

            <b-form-group class="col-md-6 mb-3" label="MRP" label-for="input-1">
              <b-form-input
                v-model="form.mrp"
                type="text"
                required
                placeholder="MRP"
                size="lg"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="col-md-6 mb-3"
              label="Barcode Number"
              label-for="input-1"
            >
              <b-form-input
                v-model="form.barcode"
                type="text"
                required
                placeholder="Barcode Number"
                size="lg"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="col-md-6 mb-3"
              label="Description"
              label-for="input-1"
            >
              <b-form-textarea
                v-model="form.description"
                type="text"
                required
                placeholder="Description"
                size="lg"
                rows="4"
              ></b-form-textarea>
            </b-form-group>

            <b-col md="12" class="d-flex justify-content-end">
              <b-button
                class="mt-3"
                type="submit"
                variant="primary"
                @click="submit"
                >Submit</b-button
              >
            </b-col>
          </b-row>
          <!-- </b-form> -->
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vueMultiSelect from "vue-multi-select";
import "vue-multi-select/dist/lib/vue-multi-select.css";
import { mapActions, mapGetters } from "vuex";
import constants from "@/constants";

export default {
  metaInfo: {
    title: "Add Product",
  },
  components: {
    vueMultiSelect,
  },
  computed: {
    ...mapGetters([
      "brandOptions",
      "productCategories",
      "baseUnit",
      "warehouses",
      "hardness",
      "sharpness",
      "color",
      "shape",
      "nooffaces",
      "clarity",
      "treatment",
      "effect",
      "taxes",
    ]),
    productId() {
      return this.$route.params.id;
    }
  },
  data() {
    return {
      imageBaseUrl: constants.imageBaseUrl,
      showOverlay: false,
      selected: null,
      options: [
        { value: null, text: "Select an option" },
        { value: "a", text: "January" },
        { value: "b", text: "February" },
      ],
      category: ["Emarald", "Ruby", "Sapphire", "Zircon"],
      form: {
        id: "",
        name: "",
        brandId: "",
        code: "",
        barcode: "",
        skuCode: "",
        skuName: "",
        description: "",
        productTaxes: "",
        unitId: "",
        purchasePrice: "",
        salesPrice: "",
        mrp: "",
        categoryId: "",
        warehouseId: "",
        variant1Id: "",
        variant2Id: "",
        variant3Id: "",
        variant4Id: "",
        variant5Id: "",
        variant6Id: "",
        variant7Id: "",
        variant8Id: "",
        isProductImageUpload: false,
        isQrCodeUpload: false,
        productUrlData: null,
        qRCodeUrlData: null,
      },
      categoryOptions: [
        {
          id: "cb44219c-ad95-4ef7-bbe2-4a5fa0285962",
          name: "Accessories",
          parentId: "f137e0e8-6561-41ad-86c7-9b908900800f",
          description: "",
        },
        {
          id: "6d979399-cbfc-4668-9c0f-973854e94b01",
          name: "Beverages",
          parentId: "3b38f232-3036-4420-843c-c13b62030af1",
          description: "",
        },
        {
          id: "4e2812fd-d77c-4bb4-8be7-d144259d4c62",
          name: "Coat",
          parentId: "680589af-7a62-4cdb-a919-637d6bba3932",
          description: "",
        },
        {
          id: "a7a04e66-d8d0-45b5-9b34-b81657c2e18b",
          name: "Computers & Accessories",
          parentId: "f137e0e8-6561-41ad-86c7-9b908900800f",
          description: "",
        },
        {
          id: "328cfc44-4f47-49bc-899b-75a2f3e2d19d",
          name: "Dairy & Bakery",
          parentId: "3b38f232-3036-4420-843c-c13b62030af1",
          description: "",
        },
        {
          id: "65d64c5f-ef47-4662-9d80-85163521e98d",
          name: "Earing",
          parentId: "2e3f7ddf-d56d-41d9-80dd-13b12b2cae67",
          description: "",
        },
        {
          id: "c57d807a-6565-4557-91fc-bbc993068195",
          name: "Emarald",
          parentId: "df611951-683e-46d7-9829-0c6116b5795b",
          description: "",
        },
        {
          id: "071bfb27-99a3-4d7d-88cb-26d3c781e708",
          name: "Fah Gems",
          parentId: null,
          description: null,
        },
        {
          id: "df611951-683e-46d7-9829-0c6116b5795b",
          name: "Glass gems",
          parentId: null,
          description: null,
        },
        {
          id: "02bbe9ac-7cee-4485-a365-7f797c6b292b",
          name: "Jean",
          parentId: "680589af-7a62-4cdb-a919-637d6bba3932",
          description: "",
        },
        {
          id: "2e3f7ddf-d56d-41d9-80dd-13b12b2cae67",
          name: "Jewels",
          parentId: null,
          description: null,
        },
        {
          id: "6fe65172-a2e9-4429-bfa4-95c40d2aea06",
          name: "Necles",
          parentId: "2e3f7ddf-d56d-41d9-80dd-13b12b2cae67",
          description: "",
        },
        {
          id: "f7df3c27-d990-4052-886c-46459b0ab75f",
          name: "Perl",
          parentId: null,
          description: "Natural and artificial perls",
        },
        {
          id: "b0c2904c-960b-41e0-83f8-8ce429117816",
          name: "Personal Care",
          parentId: "3b38f232-3036-4420-843c-c13b62030af1",
          description: "",
        },
        {
          id: "70b98420-0cc0-450b-8bdf-6adea610aea1",
          name: "Ring",
          parentId: "2e3f7ddf-d56d-41d9-80dd-13b12b2cae67",
          description: "",
        },
        {
          id: "52ed3499-de0e-4f7d-a15c-15f2ada27092",
          name: "Ruby",
          parentId: "df611951-683e-46d7-9829-0c6116b5795b",
          description: "",
        },
        {
          id: "bb75f169-580e-4dba-9b85-d9bf1232d529",
          name: "sapphire",
          parentId: "df611951-683e-46d7-9829-0c6116b5795b",
          description: "",
        },
        {
          id: "5f8e078e-adf7-48b4-9eaa-9aa0d64d94ee",
          name: "Vegetables",
          parentId: "3b38f232-3036-4420-843c-c13b62030af1",
          description: "",
        },
        {
          id: "c69977d4-02ae-475a-8562-0f312667ac65",
          name: "White perl",
          parentId: "f7df3c27-d990-4052-886c-46459b0ab75f",
          description: "",
        },
        {
          id: "07c80f3f-efb2-4e91-9787-a4acb96de563",
          name: "Zircon",
          parentId: "df611951-683e-46d7-9829-0c6116b5795b",
          description: "Zircons yellow and other colors",
        },
      ],
      variant1Options: [
        {
          id: "5cb52eec-2010-43c7-3eb8-08dac3db7fd4",
          name: "Rough",
          description: null,
        },
        {
          id: "0257bc7c-edbe-4f4e-83a2-08dac601f4d4",
          name: "Soft",
          description: null,
        },
        {
          id: "ba125c0c-bed6-4c7f-ce73-08dac6113e0d",
          name: "Medium",
          description: null,
        },
        {
          id: "3bce0d88-72a2-4c8f-31b9-08dad0edd1b2",
          name: "Tough",
          description: null,
        },
        {
          id: "b4f36667-7412-412e-462e-08dad2225007",
          name: "Mat",
          description: null,
        },
        {
          id: "93eb512d-c3ec-444c-34c0-08db20830211",
          name: "6.5 - 7.0",
          description: null,
        },
      ],
      variant2Options: [
        {
          id: "89fe4ad3-9223-4bc1-bdeb-08dac86f08c7",
          name: "Single edge",
          description: null,
        },
        {
          id: "d26397f7-ab06-49f4-bdec-08dac86f08c7",
          name: "Dual Edge",
          description: null,
        },
      ],
      variant3Options: [
        {
          id: "3e576095-ff1a-4bbd-c611-08dac3e18fb5",
          name: "White Smoke",
          description: null,
        },
        {
          id: "d2389e1e-97ce-4ee5-ea3b-08daca12bf16",
          name: "Blue",
          description: null,
        },
        {
          id: "dfb64cb6-3421-4632-20ab-08dad0ede309",
          name: "Transparent",
          description: null,
        },
        {
          id: "53d25d88-b56c-4e80-16a5-08dad22241c4",
          name: "Ocean blue",
          description: null,
        },
        {
          id: "8cdb14a7-0015-4754-6d01-08dad281f896",
          name: "Green",
          description: null,
        },
        {
          id: "55d6b6d9-dff9-496f-a9fb-08dad2c1eb09",
          name: "Magenta",
          description: null,
        },
        {
          id: "23e4ca55-08e5-4950-a3d7-08db208325dd",
          name: "Neon Green",
          description: null,
        },
      ],
      variant4Options: [
        {
          id: "59e8200a-6d0f-46a5-8b6f-08dac392b5cc",
          name: "Hexagon",
          description: null,
        },
        {
          id: "c3eb334a-def6-4e8d-4885-08dac6038161",
          name: "Pentagon",
          description: null,
        },
        {
          id: "dc875b3a-31dc-46bd-90e4-08dac86ec09f",
          name: "cuboid",
          description: null,
        },
        {
          id: "a01e8cdd-6110-4984-e2bb-08dad28266c8",
          name: "Cylinder",
          description: null,
        },
        {
          id: "23002b40-c8e7-4b54-930e-08dade83e2a5",
          name: "Oval",
          description: null,
        },
      ],
      variant5Options: [
        {
          id: "73d22186-7b2a-404b-a0b5-08dac3b030b5",
          name: "xdf",
          description: null,
        },
        {
          id: "5a25fae3-ea95-4ccc-87e4-08dac3cfd9d4",
          name: "6Shop",
          description: null,
        },
        {
          id: "cd138a4b-c395-4b86-87e5-08dac3cfd9d4",
          name: "7",
          description: null,
        },
        {
          id: "f55ffba5-f009-4503-43f9-08dac6035786",
          name: "8",
          description: null,
        },
      ],
      variant6Options: [
        {
          id: "393f03ab-76d5-4bb8-6e2f-08dac3ac86d8",
          name: "Eye Clean",
          description: null,
        },
        {
          id: "95479c49-f510-42fc-d7c9-08dade83f9e4",
          name: "VVS1",
          description: null,
        },
        {
          id: "f8b3ce2b-8ad7-4f0d-d9cf-08db208336d7",
          name: "VVS",
          description: null,
        },
      ],
      variant7Options: [
        {
          id: "813657f7-a7a2-48e1-6268-08dacea97aa0",
          name: "Unheated",
          description: null,
        },
      ],
      variant8Options: [
        {
          id: "bf05457a-c1d7-4e93-d7f8-08dac3e7aa5e",
          name: "Multi Color Under Light",
          description: null,
        },
        {
          id: "d7502eb5-9730-40e8-4ece-08dade840537",
          name: "Flashing",
          description: null,
        },
      ],
      taxOptions: [
        {
          id: "fd3e34a9-a5e2-4e18-aed3-326cea175437",
          name: "Sales Tax",
          percentage: 10,
        },
        {
          id: "7f6fa7f1-7af8-42bc-aab9-3460c8d752bd",
          name: "SGST",
          percentage: 12,
        },
        {
          id: "3cb2058f-6a6a-4c7d-8332-3d0269d8f1fc",
          name: "THB7%",
          percentage: 7,
        },
        {
          id: "2b9972d1-300e-45b0-93e4-6d47cea9991c",
          name: "CGST",
          percentage: 10,
        },
        {
          id: "5c698e75-4dfc-408e-9318-c24a69530532",
          name: "Excise Tax",
          percentage: 8,
        },
      ],
      taxConfigoptions: {
        multi: true,
        groups: false,
      },
      unitIdOptions: [
        {
          id: "e9e5b90d-30a2-4af5-ac95-56710734c84b",
          name: "Box",
          code: "Box",
          operator: null,
          value: null,
          parentId: null,
          baseUnitName: null,
        },
        {
          id: "907c5dab-b5b1-460f-b46d-ab724fa2a4ae",
          name: "Kilo Gram",
          code: "KG",
          operator: null,
          value: null,
          parentId: null,
          baseUnitName: null,
        },
        {
          id: "adffb238-ef09-4d67-8f99-b3594b62f63e",
          name: "Litters",
          code: "Lt",
          operator: null,
          value: null,
          parentId: null,
          baseUnitName: null,
        },
        {
          id: "4f0a0258-0a2e-4155-8e6a-d753db215fbd",
          name: "Piece",
          code: "Piece",
          operator: null,
          value: null,
          parentId: null,
          baseUnitName: null,
        },
      ],
      warehouseOptions: [
        {
          id: "f1336231-d3a9-42d0-b6d2-0f50040b0cd2",
          name: "Dpay",
          address: "PSR gold street ",
          contactPerson: "vishaly ",
          mobileNumber: "9123456789",
          email: "vishaly@gmail.com",
        },
        {
          id: "a1866bf7-d4e9-4775-8ef1-65310b00cffd",
          name: "FMM",
          address: "sannathi street,\ndharmapuram,\nkaraikal. ",
          contactPerson: "vini",
          mobileNumber: "9876543221",
          email: "vini@gmail.com",
        },
        {
          id: "f181fe8d-ae09-40ca-a790-99c1f1763aeb",
          name: "Columbo",
          address: "SL",
          contactPerson: "Kanhaiya",
          mobileNumber: "98765321245",
          email: "kanhaiya@gmail.com",
        },
        {
          id: "a1803633-2d54-4549-bad2-e49f1de69cb1",
          name: "B1Booth",
          address: "Bangkok",
          contactPerson: "Kamil",
          mobileNumber: "0805092551",
          email: "kamil@mmk.com",
        },
      ],
      mode: "add"
    };
  },
  methods: {
    ...mapActions([
      "getProduct",
      "addProduct",
      "updateProduct",
      "getBrandList",
      "getProductCategories",
      "getBaseUnit",
      "getWarehouses",
      "getHardness",
      "getSharpness",
      "getColor",
      "getShape",
      "getNumberofFaces",
      "getClarity",
      "getTreatment",
      "getEffect",
      "getTaxes"
    ]),
    btnLabel(values) {
      let placeholder = values.map((v) => v.name).toString();
      return placeholder ? placeholder.replaceAll(",", ", ") : "Tax";
    },
    open() {
      console.log("open");
    },
    close() {
      console.log("close");
    },
    submit() {
      if (this.form.productTaxes.length > 0) {
        this.form.productTaxes.map((v) => (v.taxId = v.id));
        this.form.productTaxIds = this.form.productTaxes.map((v) => v.taxId);
      }
      this.showOverlay = true;

      if (this.productId) {
        this.updateProduct(this.form)
          .then((c) => {
            this.showOverlay = false;
            this.$bvToast.toast("Product updated successfully", {
              title: "Success",
              variant: "success",
              autoHideDelay: 5000,
            });
            this.$router.push("/products");
          })
          .catch((err) => {
            this.showOverlay = false;
            this.$bvToast.toast(err, {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            });
          });
      } else {
      this.addProduct(this.form)
        .then((c) => {
          this.showOverlay = false;
          this.$bvToast.toast("Product added successfully", {
            title: "Success",
            variant: "success",
            autoHideDelay: 5000,
          });
          this.$router.push("/products");
        })
        .catch((err) => {
          this.showOverlay = false;
          this.$bvToast.toast(err, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
      }
    },
    uploadProof() {
      const vm = this;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        vm.form.isProductImageUpload = true;
        vm.form.productUrlData = reader.result;
      };
    },
    getFullImageUrl(path) {
      if (path) {
        // Replace backslashes with forward slashes and add "Thumbnail" after "ProductImages"
        path = path.replace(/\\/g, '/').replace('ProductImages/', 'ProductImages/Thumbnail/');
        // Ensure there's no duplicate slash
        if (this.imageBaseUrl.endsWith('/') && path.startsWith('/')) {
          path = path.slice(1);
        } else if (!this.imageBaseUrl.endsWith('/') && !path.startsWith('/')) {
          path = '/' + path;
        }
        return this.imageBaseUrl + path;
      }
      return '';
    }
  },
  async mounted() {
    this.getBrandList();
    this.getProductCategories();
    this.getBaseUnit();
    this.getWarehouses();
    this.getHardness();
    this.getSharpness();
    this.getColor();
    this.getShape();
    this.getNumberofFaces();
    this.getClarity();
    this.getTreatment();
    this.getEffect();
    this.getTaxes();

    if (this.productId) {
      this.mode = "edit"
      const currentProduct = await this.getProduct(this.productId);
      this.form = {...this.form, ...currentProduct}
    }
  },
};
</script>

<style scoped lang="scss">
.form-group label {
  font-size: 14px !important;
}
.form-control {
  background: #ffffff;
}
::v-deep.multi-select.select {
  width: 100%;
  display: block !important;
  .btn-select {
    width: 100%;
    text-align: left;
    .buttonLabel {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
